<template>
  <v-layout column>
    <small class="grey--text">
      {{ label }}
    </small>
    <v-btn-toggle v-bind="$attrs" :value="value">
      <v-btn
        text
        :value="null"
        :color="value === null ? 'primary' : ''"
        @click="$emit('input', null)"
      >
        全て
      </v-btn>
      <v-btn
        text
        :value="true"
        :color="value === true ? 'primary' : ''"
        @click="$emit('input', true)"
      >
        はい
      </v-btn>
      <v-btn
        text
        :value="false"
        :color="value === false ? 'primary' : ''"
        @click="$emit('input', false)"
      >
        いいえ
      </v-btn>
    </v-btn-toggle>
  </v-layout>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
  },
  computed: {
    options() {
      return [
        { text: '', value: null },
        { text: 'T', value: true },
        { text: 'F', value: false },
      ]
    },
  },
}
</script>

<style></style>
